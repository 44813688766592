<!-- 培训资源 -->
<template>
   <div class='train-container'></div>
</template>

<script>

export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {
  
    };
  },
  //方法集合
  methods: {
  
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
  
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    window.open('https://www.sryzh.com/course/explore?subCategory=&selectedthirdLevelCategory=&tag[tags][4]=24&filter[type]=all&filter[price]=all&filter[currentLevelId]=all&orderBy=latest','_blank')
  },
  }
</script>
<style lang='less' scoped>
.train-container{
    min-height: 500px;
}
</style>